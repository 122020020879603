<template>
  <div class="login">
    <Login />
  </div>
</template>
<script>
import Login from "@/components/auth/Login";
export default {
  components: {
    Login
  }
};
</script>