<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 offset-lg-4 col-sm-10 offset-sm-1">
        <form
          class="text-center border border-primary p-5"
          style="margin-top:70px;height:auto;padding-top:100px !important;"
          @submit.prevent="loginUser"
        >
          <input
            type="text"
            id="login"
            class="form-control mb-3"
            placeholder="Логин"
            v-model="login.login"
          />
          <!-- Password -->
          <input
            type="password"
            id="password"
            class="form-control mb-3"
            placeholder="Пароль"
            v-model="login.password"
          />
       
          <!-- Sign in button -->
          <center>
            <button class="btn btn-primary btn-block w-75 my-4" type="submit">
              Войти
            </button>
          </center>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
export default {
  data() {
    return {
      login: {
        login: "",
        password: ""
      }
    };
  },
  methods: {
    async loginUser() {
      try {
        let response = await this.$http.post("/auth/token", this.login);
        let token = response.data.jwt;

        if (token) {
          localStorage.setItem("jwt", token);
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          swal("Успех!", "Вы авторизованы", "success");
          this.$router.push("/");
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          swal("Ошибка!", "Неверный логин или пароль", "error");
        } else {
          swal("Ошибка!", "Что то пошло не так", "error");
          console.log(err);
        }
      }
    }
  },

  created() {
    if (localStorage.getItem("jwt") != null) {
      this.$router.push("/");
    }
  }
};
</script>